@import '~antd/dist/antd.css';
#root{
	min-width: 1400px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
main{
	min-width: 1400px;
}
main .ant-col-20{
	min-width: 1400px;
}
.bbb{
	width: calc(100% - 220px);
}
.aaaa{
	word-wrap:break-word;
	height: 115px;
	overflow: hidden;
}
.cancle{
	min-width: auto !important;
}
.setwidth{
	width:calc(100vw - 320px);;
}
.margina{
	margin-top: 100px;
}
.ant-carousel{
	width: 100%;
}
.leftButton[ant-click-animating-without-extra-node]:after, .rightButton[ant-click-animating-without-extra-node]:after{
  border: 0 none;
  opacity: 0;
  animation:none 0 ease 0 1 normal;
background: transparent;
}
.leftButton:hover,.rightButton:hover,.leftButton:active,.rightButton:active,.leftButton:focus,.rightButton:focus{
	background: transparent !important;
}
.blue,.blue:hover{
	color: #1890ff !important;
	margin-left: 20px;
}