.sidebar {
  position         : fixed;
  z-index          : 88;
  right            : 0;
  bottom           : 30%;
  padding          : 14px 12px 0;
  box-shadow       : 0 4px 38px 0 rgba(255, 96, 115, 0.2);
  border-radius    : 20px 0 0 20px;
  /* background-color : #5fabf1; */
}
.sidebar > div {
  cursor     : pointer;
  text-align : center;
}
.sidebar > div img {
  width : 40px;
}

.ant-popover-inner-content {
  padding: 0px 0px;
}
